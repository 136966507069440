import React from "react"
import Layout from "../components/layout.js"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import "./PageTemplate.scss"

const PageTemplate = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={data.wordpressPage.excerpt}
      />
      <h1>{data.wordpressPage.title}</h1>

      <div className="page-content-wrapper" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
    }
  }
`
